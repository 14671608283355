import { useAuth0, User } from '@auth0/auth0-react';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

export interface ApiKey {
  apiKey: string;
  createdAt: string;
  lastUsedAt: string;
  usageCount: number;
}

interface UserContextProps {
  user: User | null;
  setUser: React.Dispatch<React.SetStateAction<User>>;
  apiKeys: ApiKey[];
  setApiKeys: React.Dispatch<React.SetStateAction<ApiKey[]>>;
}

interface UserProviderProps {
  children: React.ReactNode;
}

const UserContext = createContext<UserContextProps | undefined>(undefined);

export const UserProvider: React.FC<UserProviderProps> = ({ children }) => {
  const [user, setUser] = useState<User | null>(null);
  const [apiKeys, setApiKeys] = useState<ApiKey[]>([]);
  const { isAuthenticated, user: auth0User, getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    const fetchApiKey = async () => {
      if (!isAuthenticated) return;
      try {
        if (auth0User) setUser(auth0User);
        const token = await getAccessTokenSilently();
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api-keys`, {
          headers: {
            Authorization: `Bearer ${token}`,
          }
        });
        if (!response.ok) {
          const errorData = await response.json();
          throw errorData;
        }
        const data = await response.json();
        setApiKeys(data.apiKeys);
      } catch (error) {
        const err = error as Error;
        toast.error('Failed to fetch API key: ' + err.message);
      }
    };
  
    fetchApiKey();
  }, [isAuthenticated, auth0User, getAccessTokenSilently]);

  return (
    <UserContext.Provider value={{ user, setUser, apiKeys, setApiKeys }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error('useUser must be used within a UserProvider');
  }
  return context;
};
