import React, { ReactNode } from 'react';

import './index.css';

interface StandardPageProps {
  children: ReactNode;
  title: string;
}

const StandardPage: React.FC<StandardPageProps> = ({ children, title }) => {
  return (
    <div className="standard-page">
      <h1 className='text-3xl mb-8'>{title}</h1>
      {children}
    </div>
  );
};

export default StandardPage;
