import React, { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate, useLocation } from 'react-router-dom';

import './index.css';

const Navbar: React.FC = () => {
  const { logout, user, isAuthenticated } = useAuth0();
  const navigate = useNavigate();
  const location = useLocation();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const isSelected = (path: string) => (location.pathname === path ? 'selected hover:bg-[#efefef]' : '');

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <>
      <nav className="fixed top-0 z-50 w-full bg-[#f5f5f5] dark:bg-[#f5f5f5] dark:border-gray-700">
        <div className="px-3 py-3 lg:px-5 lg:pl-3">
          <div className="flex items-center justify-between">
            <div className="flex items-center justify-start rtl:justify-end">
              <button onClick={toggleSidebar} data-drawer-target="logo-sidebar" data-drawer-toggle="logo-sidebar" aria-controls="logo-sidebar" type="button" className="inline-flex items-center p-2 text-sm text-gray-500 rounded-lg sm:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600">
                  <span className="sr-only">Open sidebar</span>
                  <svg className="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                    <path clipRule="evenodd" fillRule="evenodd" d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"></path>
                  </svg>
              </button>
              <button onClick={() => navigate('/')} className="link flex ms-2 md:me-24">
                <span className="self-center text-xl font-semibold sm:text-2xl whitespace-nowrap dark:text-black">CivAPI</span>
              </button>
            </div>
            <div className="flex items-center" style={{ display: isAuthenticated ? 'block' : 'none' }}>
              <div className="flex items-center ms-3">
                <div>
                  <button type="button" className="flex text-sm bg-gray-800 rounded-full focus:ring-4 focus:ring-gray-300 dark:focus:ring-gray-600" aria-expanded="false" data-dropdown-toggle="dropdown-user">
                    <span className="sr-only">Open user menu</span>
                    <img className="w-8 h-8 rounded-full" src={user?.picture} alt="user" />
                  </button>
                </div>
                <div className="z-50 hidden my-4 text-base list-none bg-gray-700 divide-y divide-gray-600 rounded shadow dark:bg-gray-700 dark:divide-gray-600" id="dropdown-user">
                  <div className="px-4 py-3" role="none">
                    <p className="text-sm text-white dark:text-white" role="none">
                      {user?.name}
                    </p>
                    <p className="text-sm font-medium text-gray-300 truncate dark:text-gray-300" role="none">
                      {user?.email}
                    </p>
                  </div>
                  <ul className="py-1" role="none">
                    <li>
                      <button onClick={() => navigate('/settings')} className="link w-full text-start block px-4 py-2 text-sm text-gray-300 hover:text-white hover:bg-gray-600 dark:text-gray-300 dark:hover:bg-gray-600 dark:hover:text-white" role="menuitem">Settings</button>
                    </li>
                    <li>
                      <button onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })} className="link w-full text-start block px-4 py-2 text-sm text-gray-300 hover:bg-gray-600 hover:text-white dark:text-gray-300 dark:hover:bg-gray-600 dark:hover:text-white" role="menuitem">Sign out</button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>

      {isAuthenticated &&
        <aside id="logo-sidebar" aria-label="Sidebar"
          className={`fixed top-0 left-0 z-40 w-64 h-screen pt-20 transition-transform bg-[#f5f5f5] sm:translate-x-0 dark:bg-[#f5f5f5] dark:border-gray-700 ${
            isSidebarOpen ? 'translate-x-0' : '-translate-x-full'
          }`}>
          <div className="h-full px-3 pb-4 overflow-y-auto bg-[#f5f5f5] dark:bg-[#f5f5f5]">
            <ul className="space-y-2 font-medium">
              <li>
                <button onClick={() => navigate('/')} className={`flex w-full items-center p-2 nav-link rounded-lg hover:bg-[#e6e6e6] hover:bg-[#efefef] group ${isSelected('/')}`}>
                  <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5 text-gray-600 transition duration-75 group-hover:text-gray-900" viewBox="0 0 24 24" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                    <rect x="3" y="11" width="18" height="11" rx="2" ry="2"></rect>
                    <path d="M7 11V7a5 5 0 0 1 9.9-1"></path>
                  </svg>
                  <span className="ms-3">API Keys</span>
                </button>
              </li>
              <li>
                <button onClick={() => navigate('/query')} className={`flex w-full items-center nav-link p-2 rounded-lg hover:bg-[#e6e6e6] hover:bg-[#efefef] group ${isSelected('/query')}`}>
                  <svg className="w-5 h-5 text-gray-600 transition duration-75 group-hover:text-gray-900" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 22 21">
                    <path d="M16.975 11H10V4.025a1 1 0 0 0-1.066-.998 8.5 8.5 0 1 0 9.039 9.039.999.999 0 0 0-1-1.066h.002Z"/>
                    <path d="M12.5 0c-.157 0-.311.01-.565.027A1 1 0 0 0 11 1.02V10h8.975a1 1 0 0 0 1-.935c.013-.188.028-.374.028-.565A8.51 8.51 0 0 0 12.5 0Z"/>
                  </svg>
                  <span className="ms-3">Query</span>
                </button>
              </li>
            </ul>
          </div>
        </aside>}
    </>
  );
};

export default Navbar;
