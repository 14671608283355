import React, { ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import { Auth0Provider as Auth0ReactProvider, Auth0ProviderOptions, AppState, User } from '@auth0/auth0-react';

interface Auth0ProviderProps {
  children: ReactNode;
}

const Auth0Provider: React.FC<Auth0ProviderProps> = ({ children }) => {
  const navigate = useNavigate();

  if (!process.env.REACT_APP_AUTH0_DOMAIN ||
      !process.env.REACT_APP_AUTH0_CLIENT_ID ||
      !process.env.REACT_APP_AUTH0_API_IDENTIFIER) {
    throw new Error('Missing required env variables AUTH0_DOMAIN and/or AUTH0_CLIENT_ID and/or AUTH0_API_IDENTIFIER');
  }

  const onRedirectCallback = async (appState?: AppState, user?: User) => {
    console.log('user: ', user);
    navigate(appState?.returnTo || window.location.pathname);
  };

  const providerConfig: Auth0ProviderOptions = {
    domain: process.env.REACT_APP_AUTH0_DOMAIN,
    clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
    onRedirectCallback: onRedirectCallback,
    authorizationParams: {
      redirect_uri: window.location.origin,
      audience: process.env.REACT_APP_AUTH0_API_IDENTIFIER
    }
  };

  return (
    <Auth0ReactProvider {...providerConfig}>
      {children}
    </Auth0ReactProvider>
  );
};

export default Auth0Provider;
