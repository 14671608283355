export const EVENT_OPTIONS = [
  { value: 'conflict-events', label: 'Conflicts' },
  { value: 'displacement-events', label: 'Displacements' },
  { value: 'crisis-funding-events', label: 'Response' },
  { value: 'admin-data', label: 'Admin' },
];

export const LOCALITY_OPTIONS = [
  { value: 'lb', label: 'Lebanon' },
  { value: 'global', label: 'Global' }
];

export const EVENT_TYPE_OPTIONS = {
  'conflict-events': [
    { value: 'acled', label: 'ACLED' }
  ],
  'crisis-funding-events': [
    { value: 'ocha_fts', label: 'OCHA FTS' },
    { value: 'spotlight_initiative', label: 'Spotlight Initiative' }
  ],
  'displacement-events': [
    { value: 'iom', label: 'IOM' }
  ],
  'admin-data': [
    { value: 'buildings', label: 'Buildings' },
    { value: 'land_use', label: 'Land Use' },
    { value: 'natural', label: 'Natural' },
    { value: 'places', label: 'Places' },
    { value: 'places_of_worship', label: 'Places of Worship' },
    { value: 'pois', label: 'POIS' },
    { value: 'railways', label: 'Railways' },
    { value: 'roads', label: 'Roads' },
    { value: 'traffic', label: 'Traffic' },
    { value: 'transport', label: 'Transport' },
    { value: 'water', label: 'Water' },
    { value: 'water_ways', label: 'Water Ways' }
  ]
};
