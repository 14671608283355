import React from 'react';
import StandardPage from '../../components/StandardPage';
import { useUser } from '../../context/user';

const Home: React.FC = () => {
  const { apiKeys } = useUser();

  return (
    <StandardPage title="API Keys">
      <div className="overflow-x-auto">
        <table className="table-auto w-full border border-gray-200">
          <thead className="hidden sm:table-header-group">
            <tr>
              <th className="px-4 py-2 border border-gray-300 text-left">Key</th>
              <th className="px-4 py-2 border border-gray-300 text-left">Created</th>
              <th className="px-4 py-2 border border-gray-300 text-left">Last Used</th>
              <th className="px-4 py-2 border border-gray-300 text-left">Usage Count</th>
            </tr>
          </thead>
          <tbody>
            {apiKeys.map((item, index) => (
              <tr key={index} className="block sm:table-row border-b border-gray-300 sm:border-none">
                <td className="px-4 py-2 border border-gray-300 block sm:table-cell">
                  <span className="sm:hidden font-bold">Key:</span> {item.apiKey}
                </td>
                <td className="px-4 py-2 border border-gray-300 block sm:table-cell">
                  <span className="sm:hidden font-bold">Created:</span> {new Date(item.createdAt).toDateString()}
                </td>
                <td className="px-4 py-2 border border-gray-300 block sm:table-cell">
                  <span className="sm:hidden font-bold">Last Used:</span> 
                  {isNaN(Date.parse(item.lastUsedAt)) ? ' Never' : new Date(item.lastUsedAt).toDateString()}
                </td>
                <td className="px-4 py-2 border border-gray-300 block sm:table-cell">
                  <span className="sm:hidden font-bold">Usage Count:</span> {item.usageCount || 0}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </StandardPage>
  );
};

export default Home;
