import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import Home from './screens/Home';
import Loading from './components/Loading';
import Query from './screens/Query';

interface ProtectedRouteProps {
  component: React.ComponentType<any>;
}

const Router: React.FC = () => {
  return (
    <Routes>
      <Route path='/' element={<ProtectedRoute component={Home} />} />
      <Route path='/query' element={<ProtectedRoute component={Query} />} />
    </Routes>
  );
};

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ component }) => {
  const AuthComponent = withAuthenticationRequired(component, {
    onRedirecting: () => <Loading />,
  });
  return <AuthComponent />;
};

export default Router;
